import { deviseSignIn } from '@polydice/icook-devise-unify';
import styles from './style.module.scss';
import cx from 'classnames';
import addPic from 'images/add_dish_pic.svg';

const DishAddCard = ({ currentUser }) => {
  const isLogin = currentUser?.username;

  const invokeLoginModal = () => {
    const { origin } = window.location;
    deviseSignIn({
      entry: 'add_new_dish',
      redirect: `${origin}/dishes/new`
    });
  };

  const redirect = () => {
    const { origin } = window.location;
    window.location.href = `${origin}/dishes/new`;
  };

  return (
    <div className={cx(styles.dishTagCard, styles.dishTagCardDotBorder)}>
      <div className={cx(styles.dishTagCardIndex, styles.dishTagCardRed)}>
        +
      </div>
      <picture className={styles.dishTagCardImage}>
        <img
          className={styles.dishTagCardImageSource}
          src={addPic}
          alt="AddDish"
        />
      </picture>
      <div className={styles.dishTagCardContents}>
        <p className={styles.dishAddCardText}>
          用料理照顧你所愛，讓他更有力量面對明天的挑戰。
        </p>
        <button
          className={styles.dishAddCardButton}
          onClick={isLogin ? () => redirect() : () => invokeLoginModal()}
        >
          新增一起做
        </button>
      </div>
    </div>
  );
};

export default DishAddCard;
