import PropTypes from 'prop-types';
import styles from './style.module.scss';
import cx from 'classnames';
import chefIcon from 'images/icon-chef.svg';
import editIcon from 'images/user-page/edit-block.svg';
import Badge from '../lib/Badge';

const LinkButton = ({
  href,
  icon,
  text,
  trackId = '',
  username = '',
  eventName = '',
  isEmpty = false
}) => {
  return (
    <a
      href={href}
      className={styles.linkButton}
      {...(isEmpty && {
        'data-track-id': trackId,
        'data-username': username,
        'data-where': eventName
      })}
    >
      {icon && <span>{icon}</span>}
      <span>{text}</span>
    </a>
  );
};

const renderLinkButton = (type, user, eventName = '', isEmpty) => {
  switch (type) {
    case 'recipe':
      return (
        <LinkButton
          text="寫食譜"
          icon={<i className="icon-plus-regular"></i>}
          href="/recipes/new"
        />
      );
    case 'dish':
      return (
        <LinkButton
          text="分享一起做"
          icon={<img src={chefIcon} alt="icon-chef" />}
          href="/dishes/new"
        />
      );
    case 'favorite':
      return (
        <LinkButton
          text="前往我的收藏"
          icon={<i className="icon-bookmark-regular"></i>}
          href="/favorites"
        />
      );
    case 'edit':
      return (
        <LinkButton
          text="編輯顯示區塊"
          icon={<img src={editIcon} alt="icon-edit" />}
          href={`/users/${user.username}/edit`}
          trackId="userpage-edit-sections"
          username={user.username}
          eventName={eventName}
          isEmpty={isEmpty}
        />
      );
    default:
      return null;
  }
};

const HomepageSectionBottom = ({
  bottomLinkType,
  user,
  eventName,
  isEmpty
}) => {
  return (
    <div className={styles.homepageSectionBottom}>
      {renderLinkButton(bottomLinkType, user, eventName, isEmpty)}
    </div>
  );
};

const HomepageSectionContent = ({
  title,
  children,
  isMobileScroll,
  isDraft
}) => {
  if (!title) return children;

  return (
    <section
      className={cx(styles.homepageSectionContent, {
        [styles.homepageSectionContentDraft]: isDraft
      })}
      data-mobile-scroll={isMobileScroll}
    >
      {children}
    </section>
  );
};

const EmptyContent = ({ emptyContentImageSrc, emptyContentTextArr }) => {
  return (
    <>
      {emptyContentImageSrc && (
        <div className={cx(styles.emptyContent, styles.emptyContentTop)}>
          <img
            src={emptyContentImageSrc}
            className={styles.emptyContentImage}
            alt="empty content"
          />
        </div>
      )}
      {emptyContentTextArr?.length > 0 && (
        <div className={cx(styles.emptyContent)}>
          <div className={styles.emptyContentTextArea}>
            {emptyContentTextArr.map((text, index) => (
              <span key={index}>{text}</span>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const HomepageSection = ({
  title,
  subTitle,
  type,
  className,
  link,
  children,
  isMobileScroll,
  redMoreLink,
  showMoreLink = true,
  showBottomLink,
  bottomLinkType,
  showEmptyContent = false,
  emptyContentImageSrc,
  emptyContentTextArr = [],
  titleStyle = {},
  user = {},
  eventName = '',
  isDraft = false,
  isNew
}) => {
  return (
    <section className={`${styles.homepageSection} ${className}`}>
      {title && (
        <header className={styles.homepageSectionHeader}>
          <div className={styles.homepageSectionHeaderTitleArea}>
            {subTitle && (
              <h3 className={styles.homepageSectionHeaderSubTitle}>
                {subTitle}
              </h3>
            )}
            <h2
              className={styles.homepageSectionHeaderTitle}
              style={titleStyle}
            >
              {title}
              {isNew && (
                <Badge style={{ transform: 'TranslateY(-3px)', marginLeft: 6 }}>
                  NEW
                </Badge>
              )}
            </h2>
          </div>
          {link && showMoreLink && !showEmptyContent && (
            <a
              className={cx(styles.homepageSectionHeaderReadMoreLink, {
                [styles.homepageSectionHeaderReadMoreLinkRed]: redMoreLink
              })}
              href={link}
              data-track-id="homepage"
              data-module={title}
              data-position="more"
              data-title="more"
              data-type={type}
            >
              更多
              <i className="icon-chevron-right-regular" />
            </a>
          )}
        </header>
      )}
      {children && !showEmptyContent && (
        <HomepageSectionContent
          title={title}
          isMobileScroll={isMobileScroll}
          isDraft={isDraft}
        >
          {children}
        </HomepageSectionContent>
      )}
      {showEmptyContent && (
        <EmptyContent
          emptyContentImageSrc={emptyContentImageSrc}
          emptyContentTextArr={emptyContentTextArr}
        />
      )}
      {showBottomLink && (
        <HomepageSectionBottom
          bottomLinkType={bottomLinkType}
          user={user}
          eventName={eventName}
          isEmpty={showEmptyContent}
        />
      )}
    </section>
  );
};

HomepageSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  isMobileScroll: PropTypes.bool,
  redMoreLink: PropTypes.bool,
  showBottomLink: PropTypes.bool,
  bottomLinkType: PropTypes.string,
  showEmptyContent: PropTypes.bool,
  emptyContentImageSrc: PropTypes.string,
  emptyContentTextArr: PropTypes.array,
  titleStyle: PropTypes.object,
  user: PropTypes.object
};

HomepageSection.defaultProps = {
  className: '',
  title: '',
  link: '',
  type: '',
  isMobileScroll: false,
  redMoreLink: false,
  showBottomLink: false,
  showEmptyContent: false
};

export default HomepageSection;
