import styles from './style.module.scss';

const Badge = ({ children, style }) => {
  return (
    <span className={styles.badge} style={style}>
      {children}
    </span>
  );
};

export default Badge;
