import Image from '@polydice/icook-image-helper';
import styles from './style.module.scss';
import BadgeTag from 'reactjs/components/lib/BadgeTag';
import cx from 'classnames';

const addCommas = (number) => {
  const strNum = number.toString();
  return strNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const DishTagCard = ({
  link,
  title,
  image,
  tag,
  dishesCount,
  index,
  type,
  wider = false
}) => {
  return (
    <li>
      <div
        className={cx(styles.dishTagCard, {
          [styles.dishTagCardWider]: wider
        })}
      >
        <div
          className={cx(styles.dishTagCardIndex, {
            [styles.dishTagCardIndexWider]: wider
          })}
        >
          {index + 1}
        </div>
        <a
          className={styles.dishTagCardImage}
          href={link}
          data-track-id="dishes-explore"
          data-module={title}
          data-position={index + 1}
          data-type={type}
        >
          <Image
            className={styles.dishTagCardImageSource}
            src={image}
            alt={title}
            width={400}
          />
        </a>
        <a
          className={styles.dishTagCardContents}
          href={`/hashtags/${tag}/dishes`}
        >
          <span className={styles.dishTagCardContentsTags}>
            <BadgeTag>{tag}</BadgeTag>
          </span>
          <span className={styles.dishTagCardContentsTitle}>{title}</span>
          <span className={styles.dishTagCardContentsCount}>
            {addCommas(dishesCount)} 一起做
          </span>
        </a>
      </div>
    </li>
  );
};

export default DishTagCard;
