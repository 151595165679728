import styles from './style.module.scss';

const BadgeTag = ({ children, url }) => {
  const TagName = url ? 'a' : 'span';
  const props = url ? { href: url } : {};

  return (
    <TagName {...props} className={styles.badgeTag}>
      #{children}
    </TagName>
  );
};

export default BadgeTag;
