import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import Slider from 'react-slick';
import { isClient } from 'reactjs/utils';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';
import './campaign-slick.scss';
// workaround for slickButton, ref: https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <i
    {...props}
    role="button"
    className={styles.homepageCampaignsSliderArrowPrevious}
  >
    <i className="icon-circle-solid-duo-background" />
    <i className="icon-chevron-left-regular-duo-foreground" />
  </i>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <i
    {...props}
    role="button"
    className={styles.homepageCampaignsSliderArrowNext}
  >
    <i className="icon-circle-solid-duo-background" />
    <i className="icon-chevron-right-regular-duo-foreground" />
  </i>
);

const settings = {
  autoplay: true,
  className: styles.homepageCampaignsSlider,
  prevArrow: <PreviousArrow />,
  nextArrow: <NextArrow />
};

const HomepageCampaignsItem = ({
  sectionTitle,
  type,
  position,
  withSlider,
  active,
  id,
  subtitle,
  title,
  link,
  image,
  time
}) => (
  <a
    href={link}
    className={styles.homepageCampaignsItem}
    data-with-slider={withSlider}
    data-track-id="homepage"
    data-module={sectionTitle}
    data-position={position}
    data-title={title}
    data-type={type}
  >
    <figure className={styles.homepageCampaignsItemImage}>
      <div
        className={`${styles.homepageCampaignsItemImageSource} ratio-container ratio-container-4-3`}
      >
        <Image src={imaginaryUrl(image, { width: 600 })} alt={title} />
      </div>
      <figcaption className={styles.homepageCampaignsItemImageCaption}>
        <h3
          id={withSlider ? null : id}
          className={styles.homepageCampaignsItemImageCaptionTitle}
        >
          {subtitle}
        </h3>
        <span
          className={styles.homepageCampaignsItemImageCaptionInformation}
          aria-describedby={withSlider ? null : id}
        >
          {active ? `${time} 截止` : `得獎公布：${time}`}
        </span>
        {!active && (
          <span
            className={styles.homepageCampaignsItemImageCaptionReminder}
            aria-label="活動結束"
          >
            活動結束
          </span>
        )}
      </figcaption>
    </figure>
  </a>
);

const HomepageCampaigns = ({ title, link, type, content }) => {
  return (
    <HomepageSection
      title={title}
      link={link}
      type={type}
      className={styles.homepageCampaigns}
    >
      {isClient() && (
        <Slider {...settings}>
          {content.map((item, index) => (
            <HomepageCampaignsItem
              key={item.id}
              position={index + 1}
              sectionTitle={title}
              type={type}
              {...item}
              withSlider
            />
          ))}
        </Slider>
      )}
      {!isClient() && (
        <section className={styles.homepageCampaignsSection}>
          {content.map((item, index) => (
            <HomepageCampaignsItem
              key={item.id}
              position={index + 1}
              sectionTitle={title}
              type={type}
              {...item}
            />
          ))}
        </section>
      )}
    </HomepageSection>
  );
};

HomepageCampaigns.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired
    })
  ).isRequired
};

export default HomepageCampaigns;
